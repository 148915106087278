
*{
  margin: 0px;
  padding: 0px;
  font-family: "Quicksand";
  color: white;
}

html, body{
  max-width: 100%;
  /* overflow-x: hidden; */
  overscroll-behavior-y: contain;
}

body{
  overscroll-behavior-y: contain;
}

span{
  color: black;
}

b{
  color: black;
}

i{
  color: black;
}

/*Navbar*/

.navbar{
  /*background-color: rgb(176,163,146,1);*/
  background-color: rgb(50, 50, 50);
  z-index: 999;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition-duration: 0.4s;
  padding-top: 1vh;
  padding-bottom: 1vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

  /*Leading*/

  .navbar .leading{
    margin-left: 10vw;
    display: flex;
    align-items: center;
  }

  .navbar .leading .logo{
    width: 3vw;
    height: 3vw;
    margin-right: 1vw;
    border-radius: 100%;
  }

  .navbar .leading a{
    text-decoration: none;
  }

  .navbar .leading .title{
    font-size: 1.5vw;
    border-bottom: 1px solid white;
  }

  /*Menu-Btn*/

  .navbar .menu-btn{
    display: none;
    margin-right: 10vw;
    font-size: 8vw;
    align-content: center;
    cursor: pointer;
  }


  /*Actions*/

  .navbar .actions{
    margin-right: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar .actions a{
    margin-left: 10px;
    text-decoration: none;
    text-align: center;
    font-size: 1.4vw;
    display: block;
  }

  .navbar .actions a:hover{
    text-decoration: underline;
  }


.currentLink{
  color: deeppink;
}

/*Home*/

.home{
}

  /*Intro*/

  .home .intro{
    height: 95vh;
    margin-top: 4vw;
    position: relative;
    overflow: hidden;
    background: linear-gradient(
      rgb(214,212,204),
      rgb(218,214,207),
      rgb(215,211,204),
      rgb(198,194,189),
      rgb(193,190,181),
      rgb(212,212,208)
    );
  }

  .home .intro .background{
    height: 95vh;
    object-fit: contain;
    -webkit-user-drag: none;
  }

  .home .intro .titles{
    position: absolute;
    bottom: 20%;
    right: 10vw;
    text-align: right;
    animation-name: entry;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    animation-fill-mode: forwards;
    transform: translateX(40%);
    opacity: 0.3;
  }

  .home .intro .titles .title-s{
    display: flex;
    justify-content: flex-end;
  }

  .home .intro .title{
    font-size: 7vw;
    color: rgb(0,0,0,0.8);
  }

  .home .intro .subtitle{
    margin-top: 3vw;
    color: rgb(0,0,0,0.8);
  }

  @keyframes entry{
    0%{
      transform: translateX(40%);
    }
    100%{
      transform: translateX(0%);
      opacity: 1.0;
    }
  }

  .home .intro .subtitle{
    font-size: 4vw;
  }

  /*Bio*/

  .home .bio{
    text-align: center;
    padding-left: 10vw;
    padding-right: 10vw;
    margin-top: 3vw;
    margin-bottom: 3vw;
    display: flex;
  }

    /*Header*/

    .home .bio .bio-header{
    }

      /*Profile*/

      .home .bio .bio-header img{
        width: 20vw;
        object-fit: cover;
        clip-path: circle();
        width: 18vw;
        height: 18vw;
        -webkit-user-drag: none;
      }

      /*Title*/

      .home .bio .bio-header .name{
        margin-top: 1vw;
        color: black;
      }

      /*Social*/

      .home .bio .bio-header .social{
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .home .bio .bio-header .social a.s{
        margin: 10px;
        transition: transform 250ms;
        display: inline-block;
      }

      .home .bio .bio-header .social a.s:hover{
        transform: translateY(-2px);
      }

      .home .bio .bio-header .social a.instagram svg path{
        color: black;
      }

      .home .bio .bio-header .social a.facebook svg path{
        color: #4968ad;
      }

      .home .bio .bio-header .social a.twitter svg path{
        color: #49a1eb;
      }

      .home .bio .bio-header .social a.mail svg path{
        color: black;
        transition: 1s;
      }

      .home .bio .bio-header .social a:hover::after{
        content: "";
        position: absolute;
        top: 110%;
        left: 50%;
        transform: translateX(-3px);
        color: black;
        border-radius: 100%;
        border: 3px solid white;
        box-shadow: 0 0 0 1px black;
        transition: 1s;  
      }

      .home .bio .bio-header .social a.mail:hover::after{
        border: 1px solid black;
        box-shadow: none;      
        border-radius: 10px;
        content: "francoceravolo.compositore@gmail.com";
        position: absolute;
        text-decoration: underline;
        background-color: white;
        padding: 4px;
        z-index: 999;
        top: 110%;
        left: 50%;
        transform: translateX(-50%);
        color: black;
        transition: 1s;
      }

    /*Content*/

    .home .bio .bio-content{
      margin-left: 3vw;
    }

      /*Title*/

      .home .bio .bio-content .title{
        color: black;
        margin-bottom: 1vw;
      }

      /*Description*/

      .home .bio .bio-content p{
        color: black;
        font-size: 17px;
        line-height: 160%;
        text-align: start;
        text-align: justify;
      }

      .home .bio .bio-content button{
        background-color: transparent;
        border: none;
        color: black;
        font-weight: bold;
        font-size: 1.5vw;
      }

      
      .home .bio .bio-content button:hover{
        cursor: pointer;
      }

  /*Opus*/

  .opus{
    margin-bottom: 4vw;
    margin-top: 4vw;
  }

    /*Title*/

    .opus .title{
      font-size: 2vw;
      display: inline;
      color: black;
      border: 2px solid black;
      padding: 1vw;
      border-radius: 10px;
    }
    

    /*Titles*/

    .opus .titles{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 10vw;
      margin-left: 10vw;
      margin-top: 4vw;
    }

    .opus .titles h3{
      color: black;
      font-size: 2vw;
      text-decoration: underline;
    }

    .opus .titles h3:hover{
      color: #4968ad;
    }

  /*ListEvent*/

  .list-event{
    padding-bottom: 3vw;
    margin-top: 3vw;
  }

    /*Title*/

    .list-event .list-title{
      background-color: rgb(167,157,173,1);;
      text-align: center;
      padding: 2vw;
      margin-bottom: 4vw;
    }

    .list-event .list-title .title{
      font-size: 3vw;
    }

    /*Content*/

    .list-event .list-content{
      margin-right: 10vw;
      margin-left: 10vw;
      display: flex;
      justify-content: space-between;
    }
  
  /*Event*/

  .event{
    width: 33vw;
    height: 18vw;
    border: 1px solid black;
    background-color: red;
    position: relative;
  }

    /*Image*/

    .event .event-photo{
      width: 33vw;
      height: 18vw;
    }

    .event .event-photo img{
      width: 33vw;
      height: 18vw;
      object-fit: cover;
      -webkit-user-drag: none;
    }

    /*Title*/

    .event .event-title{
      width: 33vw;
      position: absolute;
      bottom: 0;
      background-color: rgb(0,0,0,0.8);
      width: 100%;
    }

    .event .event-title h2{
      padding: 1vw;
      text-align: center;
      font-size: 1.5vw;
    }

  /*Cit*/

  .home .cit{
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 3vw;
    padding-bottom: 3vw;
    background-color: rgb(0,0,0,0.9);
    max-width: 100%;
  }

    /*Description*/

    .home .cit .description p{
      font-weight: bold;
      text-align: center;
      font-family: Georgia, serif;
      font-size: 1.7vw;
      font-style: italic;
      line-height: 1.45;
    }

    /*Quotattion*/

    .home .cit .description p span{
      display: inline;
    }
  

/*Composition*/
.composition{
}

    /*Intro*/

    .composition .intro-composition{
      width: 100vw;
      height: 80vh;
      position: relative;
    }

      /*Image*/

      .composition .intro-composition .background{
        width: 100vw;
      }

      .composition .intro-composition .background img{
        width: 100vw;
        height: 80vh;
        object-fit: cover;
        object-position: center;
      }

      /*Title*/

      .composition .intro-composition .title{
        width: 100vw;
        position: absolute;
        text-align: right;
        bottom: 7%;
        right: 4%;
      }

      .composition .intro-composition .title h1{
        display: inline;
        padding: 1vw;
        border-radius: 10px;
        font-size: 5vw;
      }

    /*List*/

    .composition{
    }

    .composition .list-audio{
    }

      /*Heder*/

      .composition .list-audio .header{
        position: sticky;
        z-index: 3;
        text-align: center;
        top: 13vh;
        margin-top: 2vw;
      }

      .composition .list-audio .header .title{
        padding: 1vw;
        display: inline;
        background-color: #515b64;
        border-radius: 10px;
        border: 1px solid black;
      }

      /*Content*/

      .composition .list-audio .content{
        margin-left: 10vw;
        margin-right: 10vw;
        margin-top: 2vw;
        display: flex;
        justify-content: start;
        flex-flow: row wrap;
        flex-wrap: wrap;
      }   


/*Models*/

  /*AudioTile*/

  .audio-tile{
    width: 17vw;
    height: 21vw;
    border: 1px solid black;
    position: relative;
    margin: 1vw;
    overflow: hidden;
    transition: all 0.3s;
    cursor: pointer;
  }

  .audio-tile:hover{
    transform: scale(1.1);
  }

    /*Image*/
    .audio-tile .audio-image{
      width: 100%;
      height: 100%;
    }

    /*Download*/
    .audio-tile .audio-download{
      color: black;
      position: absolute;
      top: 5px;
      right: 5px;
      background-color: black;
      padding: 5px;
    }

    .audio-tile .audio-download svg path{
      color: white;
    }


    /*Download*/
    .audio-tile .audio-info{    
      color: black;
      position: absolute;
      top: 5px;
      left: 5px;
      background-color: black;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
    }

    .audio-tile .audio-info svg path{
      color: white;
    }

    /*Audio Content*/
    .audio-tile .audio-content{
      position: absolute;
      width: 100%;
      bottom: 0;
      background-color: rgb(0,0,0,0.75);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 4px;
    }

    /*Info*/

    .info h2{
      font-size: 1.1vw;
      padding-bottom: 5px;
      text-align: center;
      color: orange;
    }

    .hidden{
      display: none;
    }

    .audio-tile .info{
      position: absolute;
      top: 40px;
      padding: 5px;
      background-color: rgb(0,0,0,0.75);
    }

    .hidden h2{
      font-size: 10px;
    }

      /*Top*/

      .audio-tile .audio-content .top{
        width: 100%;
      }

      .audio-tile .audio-content .top h2{
        font-size: 1.2vw;
      }
  

    /*Bottom*/


      .audio-tile .audio-content .top h2{
        text-align: center;
      }

     .audio-tile .audio-content .bottom{
      width: 100%;
     }

      /*Player*/

      .audio-tile .audio-content .bottom .audio-player{
        display: none;
        height: 3vw;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }

      /*Play Button*/

      .play-icon{
        cursor: pointer;
        border: none;
        background-color: transparent;
      }

      .play-icon svg{
        padding: 0.7vw;
        border: 1px solid white;
        border-radius: 10%;
      }

      .audio-tile .audio-content .bottom .play-icon svg path{
        border: none;
        cursor: pointer;
        color: white;
      }

      /*Time and */

      .audio-content .audio-player span{
        color: white;
      }

      /*Seek Slider*/

      .seek-slider{
        -webkit-appearance: none;
        cursor: pointer;
        width: 40%;
        border-radius: 10px;
        height: 0.9vw;
        background-color: white;
        color: black;
      }

      /*Volume Slider*/

      input[type="range"]::-webkit-slider-thumb{
        background-color: white;
        color: black;
      }

      .volume-slider{
        appearance: none;
        -webkit-appearance: slider-vertical;
        cursor: pointer;
        transition-duration: 0.5s;
        border-radius: 10px;
        height: 30px;
        width: 10px;
        color: red;
      }

      .volume-slider:hover{
        height: 70px;
        transform: translateY(-20px);
      }



  /*Loading*/

  .loading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: black;
  }

    .loading h1{
      color: white;
      text-align: center;
      font-size: 4vw;
      animation-name: load;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
    }

  /*Letter*/

  .letter {
    background-color: transparent;
    width: 7vw;
    height: 7vw;
    perspective: 1000px;
    cursor: default;
    margin: 0.5vw;
    border-radius: 10px;
  }
  
  /* This container is needed to position the front and back side */
  .letter-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    border-radius: 10px;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .letter:hover .letter-inner {
    transform: rotateY(180deg);
    animation-name: tri;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-direction: alternate-reverse;
  }

  @keyframes tri{
    0%{
      box-shadow: 5px 5px 8px 2px #888888;
    }
    50%{
      box-shadow: 20px 20px 8px 2px #888888;
    }
    100%{
      box-shadow: 5px 5px 8px 2px #888888;
    }
  }
  
  /* Position the front and back side */
  .letter-front, .letter-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    border-radius: 10px;
  }
  
  /* Style the front side (fallback if image is missing) */
  .letter-front {
    color: black;
    width: 7vw;
    height: 7vw;
    border-radius: 10px;
    background-color: rgb(167,157,173,1);
    text-align: center;
    box-shadow: 5px 5px 8px 2px #888888;
  }

  .letter-front h1{
    position: absolute;
    width: 7vw;
    height: 7vw;
    font-size: 6vw;
    text-align: center;
  }
  
  /* Style the back side */
  .letter-back {
    transform: rotateY(180deg);
    color: black;
    width: 7vw;
    height: 7vw;
    border-radius: 10px;
    background-color: #544E5E;
    text-align: center;
    box-shadow: 5px 5px 8px 2px #888888;
  }

  .letter-back h1{
    position: absolute;
    width: 7vw;
    height: 7vw;
    font-size: 6vw;
    text-align: center;
  }

  /*Animations*/

  @keyframes load {
    0%{
      opacity: 1;
    }
    100%{
      opacity: 0.3;
    }
  }


/*Footer*/

.footer{
  margin: 2vh;
}

.footer h3{
  color: black;
}

/*Page404*/

.page404{
}

  .page404 center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
  }
  /*Title*/

  .page404 h1{
    color: black;
  }

@media only screen and (max-width: 1000px){

  
  

  /*Navbar*/

  .navbar{
    flex-wrap: wrap;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

    /*Leading*/

    .navbar .leading {
    }

    .navbar .leading .title{
      font-size: 6vw;
    }

    .navbar .leading .logo{
      width: 7vw;
      height: 7vw;
    }

    /*Menu Button*/

    .navbar .menu-btn{
      display: block;
    }

    /*Actions*/

    .navbar .actions{
      border-top: 0.01px solid white;
      display: none;
      width: 100%;
      margin-right: 0vw;
      margin-top: 3vw;
      margin-bottom: 3vw;
    }

    .navbar .actions a{
      font-size: 5vw;;
    }

    .navbar.responsive .actions{
      display: block;
    }

  /*Home*/

  /*Intro*/

  .home .intro{
    height: 80vh;
  }

  .home .intro .background{
    width: 100vw;
    height: 80vh;
    object-fit: cover;
  }

    /*Titles*/

    .home .intro .titles{
      width: 100vw;
      bottom: 0%;
      right: 0%;
      padding: 1vw;
      text-align: center;
      background-color: rgb(123, 140, 160, 0.7);
    }


    /*Title*/

    .home .intro .title{
      font-size: 9vw;
      color: white;
    }

    .home .intro .subtitle{
      font-size: 7vw;
      color: white;
      font-style: italic;
    }

    .letter{
      display: none;
    }



  
  /*Bio*/

  .home .bio{
    display: block;
    margin-top: 6vw;
    margin-bottom: 6vw;
  }

    /*Header*/

    .home .bio .bio-header{
      margin-right: 0vw;
      margin-bottom: 6vw;
    }

    .home .bio .bio-header img{
      -webkit-user-drag: none;
      width: 40vw;
      height: 40vw;
    }

    /*Content*/

    .home .bio .bio-content{
      margin-left: 0vw;
    }

    .home .bio .bio-content p{
      text-align: justify;
    }

    .home .bio .bio-content button{
      text-align: center;
      font-size: 5vw;
      background-color: rgb(255,255,255,0.7);
      width: 100%;
      display: block;
    }


    /*Social*/

  /*Cit*/

    /*Description*/

    .home .cit .description p{
      font-size: 5vw;
    }

    /*Quotattion*/

    .home .cit .description span{
      font-size: 8vw;
    }

  /*Opus*/

  .opus{
    margin-top: 14vw;
    margin-bottom: 12vw;
  }

    /*Title*/ 

    .opus .title{
      font-size: 4vw;
      margin-right: 10vw;
      margin-left: 10vw;
    }

    /*Titles*/

    .opus .titles{
      margin-top: 8vw;
      display: block;
    }

    .opus .titles h3{
      text-align: center;
      font-size: 5vw;
      margin-bottom: 2vw;
      margin-top: 5vw;
    }

  /*List Event*/

  .list-event{
    margin-top: 8vw;
  }

  .list-event .list-title{
    margin-bottom: 8vw;
  }

  .list-event .list-title .title{
    font-size: 8vw;
  }

  .list-event .list-content{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0vw;
    margin-right: 0vw;
  }


  /*Event*/

  .event{
    width: 80vw;
    height: 50vw;
    margin-bottom: 6vw;
  }

    /*Image*/

    .event .event-photo{
      width: 80vw;
      height: 50vw;
    }

    .event .event-photo img{
      width: 80vw;
      height: 50vw;
      -webkit-user-drag: none;
    }

    /*Title*/

    .event .event-title h2{
      font-size: 4vw;
    }



  /*Composition*/

  .composition .intro-composition{
    height: 50vh;
  }

  .composition .intro-composition .title h1{
    font-size: 8vw;
  }

  .composition .intro-composition .background img{
    height: 50vh;
  }

  /*Title*/



  .composition .list-audio{
    align-items: center;
    margin-right: 0vw;
    margin-left: 0vw;
    width: 100vw;
  }

    /*Header*/

    .composition .list-audio .header{
      top: 12vh;
      margin-top: 5vw;
    }

    .composition .list-audio .header .title{
      padding: 2vw;
    }

/*Models*/

  /*Audio Tile*/

  .audio-background{
    width: 100vw;
    display: flex;
    justify-content: center;
  }

  .audio-tile{
    margin-top: 3vw;
    margin-bottom: 3vw;
    width: 75vw;
    height: 90vw;
    display: block;
    text-align: center;
  }

  .audio-tile:hover{
    transform: scale(1);
  }

  .audio-tile .audio-content{
    height: 25%;
    padding-top: 0px;
  }

  .audio-tile .audio-content .top h2{
    font-size: 4.5vw;
  }

  
  .audio-tile .audio-content .bottom .audio-player{
    justify-content: start;
    padding-right: 5%;
    padding-left: 5%;
    padding-top: 5%;
  }

  .info h2{
    font-size: 3.1vw;
  }

  /*Volume*/
  .volume-slider{
    display: none;
  }

  /*Time*/
  .audio-content .audio-player span{
    display: none;
  }

  .seek-slider{
    flex: 4;
  }

  .play-icon{
    flex: 1;
    font-size: 6vw;
  }

  .play-icon svg{
    border: none;
  }


  /*Social*/

  .home .bio .bio-header .social a.mail:hover::after{
    content: "";
  }  

  /*Loading*/

  .loading h1{
    font-size: 8vw;
  }

}


.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}